.chart-container{
    background: #ffffff;
    box-shadow: 0px 6px 24px rgba(0, 0, 0, 0.1);
    border-radius: 18px;
    height: fit-content;
}

.funnel-ageing-container{
    display: flex;
    width: inherit;
}