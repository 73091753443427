.dropdownLayout {
    width: 220px;
    height: 40px;
    position: relative;
    display: inline-block;
    padding-left: 0%;
}


.act_like_dropdown {
    z-index: 100;
    float: right !important;
    position: relative;
    min-width: 100%;
    margin: 2px;
    padding: 10px;
}

.right_close_button {
    float: right;
    margin: 5px;
}

.MuiFormControlLabel-root {
    margin: 0 !important;
}