.alert-container {
  width: auto;
  z-index: 9;
  margin-top: 30px;

  /* Inside Auto Layout */
  display: flex;
  justify-content: center;
  align-items: 'flex-start';
  height: 210px;
  left: 0px;
  /* top: 0px; */
  border-radius: 10px;
  background: #ffffff;
  /* Shadow / 1 (default) */

  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2),
    0px 1px 1px rgba(0, 0, 0, 0.14), 0px 1px 3px rgba(0, 0, 0, 0.12);
}

.alert-heading {
  width: fit-content;
  display: flex;
  justify-content: flex-start;
  padding-left: 25px;
}

.sub-alert-container {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  padding-top: 15px;
}
