.header-container {
}

.header {
  font-style: normal !important;
  font-weight: bold !important;
  font-size: 24px !important;
  line-height: 133.4% !important;
  color: #1e4620 !important;
}

.back-header-container {
  display: flex;
  width: 25px;
  align-items: center;
}
.MuiSwitch-switchBase {
  top: "8px";
  left: "10px";
}

.MuiSwitch-root {
  background: transparent !important;
}

.demo {
  width: 150px;
  padding-top: 32px !important;
}

.demo .MuiOutlinedInput-root {
  border-radius: 4px !important;
}
