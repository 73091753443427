.container-align {
  display: flex;
  justify-content: center;
  /* align-items: center; */
  flex-direction: column;
  min-height: 100px;
}

.alert-container {
  /*    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    padding: 0px;

    width: 1319px;
    height: 249px;
    left: 0px;
    top: 0px;

    background: #FFFFFF;
    /* Shadow / 5 */

  /* box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px rgba(0, 0, 0, 0.14), 0px 1px 14px rgba(0, 0, 0, 0.12);
    border-radius: 18px; */

  /* Inside Auto Layout */
}
