.chart-container {
    background: #ffffff;
    box-shadow: 0px 6px 24px rgba(0, 0, 0, 0.1);
    border-radius: 18px;
    height: fit-content;
    margin-bottom:24px;
    padding: 24px 32px;
    width: inherit;
    min-height: 200px;
    
}

.new-chart-container {
    width: inherit;
    min-height: 200px;
}

.frequency-container{
    margin-left:15
}

.chart-attributes-container{
    display: flex;
    justify-content: space-between;
    width: auto;
}

.MuiToggleButton-root.Mui-selected{
    background-color: aliceblue !important;
}

.row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
  }
  
.column {
    display: flex;
    flex-direction: column;
    flex-basis: 100%;
    flex: 1;
  }