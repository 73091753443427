.dropdownLayout{
    width: 180px;
    height: 40px;
    padding-left: 0%;
}


.act_like_dropdown{
    float: right !important;
    position: relative;
    min-width: 100%;
    padding: 16px 16px 16px 8px;
    gap: 8px;
    background: #FFFFFF;
    box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.25);
    border-radius: 8px;
}

.right_close_button{
    float: right;
    margin: 5px;
}
.MuiFormControlLabel-root{
    margin: 0 !important;
}

