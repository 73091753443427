.MuiFormControl-marginDense {
  margin-top: 8px !important;
}

.MuiOutlinedInput-marginDense.MuiAutocomplete-inputRoot.Mui-focused {
  gap: 6px;
}

.chip-label {
  color: var(--text-primary, rgba(0, 0, 0, 0.87));
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: "Nunito Sans";
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0.16px;
  display: flex;
  align-items: center;
}

.chip-label>img {
  margin: 4px;
  height: 20px;
  width: 20px;
  border-radius: 50%;
  background-color: #fff;
}

.MuiChip-label.MuiChip-labelMedium {
  padding: 0;
}

.MuiChip-deleteIcon.MuiChip-deleteIconColorDefault {
  margin: 0 2px !important;
  color: #616161 !important;
}

.relation-icon {
  color: rgba(3, 3, 3, 0.6) !important;
}

.MuiAutocomplete-inputRoot {
  border-color: rgba(0, 0, 0, 0.23) !important;
}

.MuiAutocomplete-inputRoot.Mui-focused,
.MuiAutocomplete-inputRoot.Mui-expanded {
  border-color: rgba(0, 0, 0, 0.23) !important;
}

.MuiAutocomplete-inputRoot.Mui-focused:active,
.MuiAutocomplete-inputRoot.Mui-focused:hover {
  border-color: rgba(0, 0, 0, 0.23) !important;
}

.MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline,
.MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline:hover {
  border: 1px solid rgba(0, 0, 0, 0.23) !important;
  border-color: rgba(0, 0, 0, 0.23) !important;
}

.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline,
.MuiOutlinedInput-notchedOutline:hover {
  border-color: rgba(0, 0, 0, 0.23) !important;
}

.input,
.input:hover,
.input:focus,
.input:active {
  border-color: rgba(0, 0, 0, 0.23) !important;
}

input::placeholder,
.input::placeholder {
  font-family: 'Nunito Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 12px;
  display: flex;
  align-items: center;
  letter-spacing: 0.15px;
  color: rgba(3, 3, 3, 0.6) !important;
  opacity: 0.5 !important;
  flex: none;
  order: 0;
  align-self: stretch;
  padding-top: 0.8px;
  flex-grow: 0;
}

.MuiChip-deleteIcon {
  height: 16px !important;
  width: 16px !important;
}

.MuiChip-deleteIcon.MuiChip-deleteIconColorDefault {
  margin: 0 4px !important;
}

.MuiAutocomplete-paper {
  padding: 0 !important;
  border-radius: 8px !important;
}

.MuiAutocomplete-listbox {
  padding: 0 !important;
}

.MuiAutocomplete-paper li,
.render-group-list {
  margin: 4px;
  border-radius: 6px;
}

.MuiAutocomplete-paper li:hover,
.render-group-list:hover {
  background-color: rgba(242, 245, 244, 1) !important;
}

.render-group-container {
  margin: 4px;
  border-radius: 8px;
}

.render-group-body {
  display: grid;
  gap: 4px;
}

.render-group-list {
  margin: 0;
  padding-left: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  height: 42px;
  align-self: stretch;
  /* font-size: 14px; */
  cursor: pointer;
}

.render-group-list {
  color: var(--text-primary, rgba(0, 0, 0, 0.87));
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: "Nunito Sans";
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
}

.dropdownelements {
  display: flex;
  align-items: center;
  width: 100%;
}

.imgblock{
  display: flex;
  align-items: center;
  gap: 10px;
}

/* 
.MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] {
  padding-right: 32px !important;
}

.MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"]:hover,
.MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"]:active,
.MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"]:focus {
  padding-right: 60px !important;
} */

.MuiAutocomplete-paper .MuiAutocomplete-listbox::-webkit-scrollbar {
  width: 3px;
  height: 3px;
}

.MuiChip-root.MuiAutocomplete-tag {
  /* min-width: fit-content !important; */
  max-width: 110px !important;
  display: flex !important;
  border-radius: 16px !important;
  height: 28px !important;
  background-color: var(--grey-grey-200, #EEE) !important;
}

.MuiAutocomplete-tag {
  margin: 0px !important;
  height: max-content !important;
}

.MuiAutocomplete-option {
  /* padding: 6px !important; */
}