@media (min-width: 600px){
  .MuiTab-root {
    min-width: 72px !important;
    padding-right: 20px !important;
    padding-left: 20px !important;
  }
}

.PrivateTabIndicator-root-16 {
  height: 3px !important;
  
}



.MuiTab-textColorInherit.Mui-selected {
  opacity: 1;
  /* color: #109cf1; */
}








