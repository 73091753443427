.alert-bar {
  width: 300px;
  height: 45px;
  left: 0px;
  top: 48px;
  margin-left: 10px;
  margin-right: 10px;
  /* Inside Auto Layout */

  flex: none;
  order: 1;
  flex-grow: 0;
}

.alert-bar-content {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-bottom: 1px solid #EFEFEF;
  align-items: center;
  height: inherit;
  margin: 0 16px 0px 16px;
}

.alert-text {
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 16px;
  color: #A0A0A0;
}

.alert-count-container {
  width: fit-content;
  height: 24px;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */

  text-align: right;

  color: #000000;
}

.alert-count {
  display: flex;
  align-items: center;
  gap: 12px;
  width: fit-content;
  justify-content: flex-end;
}
.alert-heading {
  align-items: center;
}

a {
  text-decoration: none;
  color: rgba(0, 0, 0, 0.87);
}
