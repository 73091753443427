.scroll.or {
  margin: 10px 0;
  padding: 10px;
  border: 1px dashed #21DA8C;
  border-radius: 12px
}

.scroll {
  margin-right: -20px;
  padding-right: 20px;
}

.scroll::-webkit-scrollbar-track {
  background-color: #f5f5f5;
}

.scroll::-webkit-scrollbar {
  width: 2px;
  height: 5px;
  background-color: #f5f5f5;
}

.scroll::-webkit-scrollbar-thumb {
  background-color: #dddd;
  border: 5px solid #d8d8d8;
  border-radius: 16px;
}

.subfilterButton {
  color: #37CAD3;
  display: flex;
  height: 30px;
  width: fit-content;
  padding: 4px 5px;
  /* margin-bottom: 10px !important; */
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 4px;
  font-family: "Nunito Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
}

.subfilterButton.marged {
  margin-left: 34px !important;
}

.subfilterButton:disabled {
  color: rgba(0, 0, 0, 0.26);
  gap: 4px;
  cursor: not-allowed;
}

.subfilterButton svg {
  height: 18px;
  width: 18px;
}

.and-button {
  background-color: white !important;
  margin-top: 10px !important;
  height: 32px !important;
  width: 55px !important;
  font-size: 13px;
  font-weight: 800;
  border-radius: 24px;
  color: rgba(33, 218, 140, 1) !important;
  border: 1px solid var(--primary-border, rgba(55, 234, 158, 0.50)) !important;
  box-shadow: none !important;
}

.and-button:hover {
  box-shadow: 0 0 6px 0 rgba(55, 234, 158, 0.50) !important;
}