
.actionSide{
    display: flex;
    gap: 16px;
    align-items: center;
}
.ActionDialog{
    justify-content: space-between;
    align-items: center;
}


.MuiDialog-paper{
    width: 921px!important;
}
.dialogTitle {
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    /* identical to box height, or 119% */
    align-items: center;
    letter-spacing: 0.15px;
    
    color: #000000;
    }
    
 