.label {
    /* width: 232px;
height: 20px; */


font-style: normal;
font-weight: 500;
font-size: 14px;
line-height: 143%;
padding-top: 5px;
/* identical to box height, or 20px */


color: #BEBEBE;
}
.Toastify__toast {
    min-height: 20px !important;
    padding: 0px 8px 0px 0px !important;
    width: fit-content;
}

.Toastify__toast-container {
    width: auto !important;
}

.MuiAlert-message{
    width: max-content !important;
    padding: 2px 0px !important;

}

.MuiAlert-icon{
    padding: 1px 0px !important;
}

.Toastify__toast-body {
    padding: 0px 0px 0px 18px !important;
    margin: 0px !important;
    color: rgba(0, 0, 0, 0.6) !important;
    font-size: 14px !important;
    font-weight: 400 !important;
    line-height: 143% !important;
    font-family: 'Nunito Sans',Arial, Helvetica, sans-serif;

}

.Toastify__close-button {
    color: rgba(0, 0, 0, 0.6) !important;
    padding: 10px 14px !important;
    margin: 0px !important;
}

.Toastify__toast--error {
    background: rgb(253, 237, 237) !important;
    border-radius: 20px !important;  
    height: 40px !important;
}

.Toastify__close-button--error {
    padding: 10px 14px !important;
    margin: 0px !important;
}

.Toastify__progress-bar--error{
    background-color: #ef5350 !important;
}

.Toastify__toast--success {
    background: rgb(237, 247, 237) !important;
    border-radius: 20px !important;  
    height: 40px !important;
}

.Toastify__close-button--success {
    padding: 10px 14px !important;
    margin: 0px !important;
}

.Toastify__progress-bar--success{
    background-color: #4caf50 !important;
}

.Toastify__toast--warning {
    background: rgb(255, 244, 229) !important;
    border-radius: 20px !important;  
    height: 40px !important;
}

.Toastify__close-button--warning {
    padding: 10px 14px !important;
    margin: 0px !important;
}

.Toastify__progress-bar--warning{
    background-color: #ff9800 !important;
}

.Toastify__toast--info {
    background: rgb(229, 246, 253) !important;
    border-radius: 20px !important;  
    height: 40px !important;
}

.Toastify__close-button--info {
    padding: 10px 14px !important;
    margin: 0px !important;
}

.Toastify__progress-bar--info{
    background-color: #03a9f4 !important;
}
.createButton{
    font-weight: 800;
    line-height: 22px;

}
.createButton1{
    font-weight: 800;
    line-height: 22px;
    border: 1px solid !important;
    border-radius: 16px !important;
}

 /* .MuiInputBase-root{
      border:0.8px solid rgba(0, 0, 0, 0.23)!important;
      border-radius:"4px";
      padding: 9;
    } */
    /* .MuiInputBase-root:hover {
      border: 0px!important;
    }
    .MuiInputBase-root:focus {
      border: 0px!important;
    }
   */