.chart-container {
  background: #FFFFFF;
  box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.1);
  border-radius: 18px;
  width: inherit;
  height: 100%;
   /* padding: 0px 32px 24px 32px; */ 
  
}
.funnel-align{
  padding-top: 10px;
  display: grid;
  justify-items: center;
  gap: 0px;
}
a{
  cursor: pointer;
}
.algin{
  position: relative;
    bottom: 3.6rem;
    font-weight: 400;
font-size: 16px;
cursor: pointer;
line-height: 19px;

color: #A0A0A0;

}
[id^="percentage"]:hover{
  
}
[id^="percentage"]{
  /* position: absolute; */
  right: 22px;
  color: rgba(33, 218, 140, 1);
  font-family: 'Proxima Nova';
  font-style: normal;
  font-weight: 700;
  font-size: 10px;
  transform: rotate(0deg);
  display: flex;
  line-height: 12px;
}
.flexclass{
  display: flex;
}
.negative:hover{
  color: rgba(243, 123, 107, 1)!important;
}

.negativeNumber{
  color: rgba(243, 123, 107, 1)!important;
}

[id^="attribute"]{
  position: relative!important;
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  color: #BEBEBE;
  right: 3vw;
  cursor: pointer;
}
[id^="attribute"]:hover {
  color:rgba(33, 218, 140, 1);
}
.percentageList{
  display: grid;
  height: 80%;
  margin: 45px 0vw 0px 1.7vw;
  transform: skew(345deg);
  position: relative;
  left: -32%;
}
.attributeList{
  display: grid;
  height: 80%;
  margin: 36px 0vw 0px 2vw;
  text-align: center;
}
.textfont {
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  text-align:right;
}
.funnel-ageing-container {
  display: flex;
}

.net-new-account-header {
  display: flex;
  flex-direction: column;
}

.caption {
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 157%;
  letter-spacing: 0.1px;
  color: #000000;
  font-family:'Nunito Sans', sans-serif;
}

.sub-caption {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 166%;
  letter-spacing: 0.4px;
  color: #A0A0A0;
  font-family:'Nunito Sans', sans-serif;
}

#funnel-finder g[class$="-legend"] {
  opacity: 0 !important;
}
.main-heading {
  justify-content: flex-start;
  padding-top: 20px;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 157%;
  letter-spacing: 0.1px;
  font-family:'Nunito Sans', sans-serif;
  /* font-family: "Montserrat", "Poppins", "Roboto", "Segoe UI", "Helvetica Neue",
    sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"; */

  font-size: 16px;
    font-weight: bold;
    color: #595959;
}
.heading {

  font-style: normal;
  font-weight: bold;
  padding-right: 3px;
  font-size: 16px;
  line-height: 157%;
  letter-spacing: 0.1px;
  color: #595959;

  font-family:'Nunito Sans', sans-serif;
  /* font-family: "Montserrat", "Poppins", "Roboto", "Segoe UI", "Helvetica Neue",
    sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"; */

  /* font-size: 16px;
    font-weight: bold;
    color: #000000; */
}
.filter-heading{
font-style: normal;
font-weight: bold;
font-size: 12px;
line-height: 15px;
/* identical to box height */

letter-spacing: 0.08em;

color: #8A8A8A;
}
.sub-heading{
font-style: normal;
font-weight: 600;
font-size: 14px ;
line-height: 19px ;
color: #A0A0A0 !important;
}
