.funnel-wrap {
  max-width: 108px;
  position: relative;
  /* margin-top: 30px; */
  top: 1.5vw;
  right: 6vh;
  }
  
  #funnel1 {
  /*   margin: 5rem auto; */
    position: relative;
    text-align: center;
    width: 11rem;
  }
  #funnel1:before, #funnel1:after {
    border-color: #FFFFFF;
    border-style: solid;
    border-top-color: transparent;
    box-sizing: border-box;
    content: '';
    position: absolute;
    top: 0;
  }
  
  #funnel1:before {
    border-width: 0 4rem 20rem 2.4rem;
    border-right-color: transparent;
    left: 0;
    z-index:1
  }
  #funnel1:after {
    border-width: 0 1rem 20rem 5rem;
    border-left-color: transparent;
    right: 0;
  }
  #funnel1 #funnel {
    padding: 0.59rem 0;
    margin-bottom: 7px;
    /* transition: all 0.3s cubic-bezier(0.55, 0.085, 0.68, 0.53); */
    position: relative;
  }
  #funnel1 #funnel:nth-child(1) {
    animation-duration: 2s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: placeHolderShimmer;
    animation-timing-function: linear;
    background-color: #f6f7f8;
    background: linear-gradient(to right, #eeeeee 8%, #bbbbbb 18%, #eeeeee 33%);
    background-size: 800px 104px;
    position: relative;    /* background-image: linear-gradient(
      90deg,
      rgba(#fff, 0) 0,
      rgba(#fff, 0.2) 20%,
      rgba(#fff, 0.5) 60%,
      rgba(#fff, 0)
    ); */

		/* background-repeat: repeat-y; */

    /* animation: skeleton-loading 1s linear infinite alternate;	 */
  }

  #funnel1 #funnel:nth-child(2) {
    animation-duration: 2s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: placeHolderShimmer;
    animation-timing-function: linear;
    background-color: #f6f7f8;
    background: linear-gradient(to right, #eeeeee 8%, #bbbbbb 18%, #eeeeee 33%);
    background-size: 800px 104px;
    position: relative;    
    /* background-image: linear-gradient(-90deg, rgba(0, 0, 0, 0.25) 0%, rgba(0, 0, 0, 0) 50%, rgba(0, 0, 0, 0.25) 100%); */
  }
  #funnel1 #funnel:nth-child(6) {
    animation-duration: 2s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: placeHolderShimmer;
    animation-timing-function: linear;
    background-color: #f6f7f8;
    background: linear-gradient(to right, #eeeeee 8%, #bbbbbb 18%, #eeeeee 33%);
    background-size: 800px 104px;
    position: relative;    
    /* background-image: linear-gradient(-90deg, rgba(0, 0, 0, 0.25) 0%, rgba(0, 0, 0, 0) 50%, rgba(0, 0, 0, 0.25) 100%); */
  }

@keyframes skeleton-loading {
  0%{
    background-color:rgba(255, 255, 255, 0.38);
  }
  100%{
    background-color: #CECECE;
  }
}  
@keyframes placeHolderShimmer {
  0% {
    background-position: -800px 0
  }
  100% {
    background-position: 800px 0
  }
}


  #funnel1 #funnel:nth-child(3) {
      animation-duration: 2s;
      animation-fill-mode: forwards;
      animation-iteration-count: infinite;
      animation-name: placeHolderShimmer;
      animation-timing-function: linear;
      background-color: #f6f7f8;
      background: linear-gradient(to right, #eeeeee 8%, #bbbbbb 18%, #eeeeee 33%);
      background-size: 800px 104px;
      position: relative;    
  }
  #funnel1 #funnel:nth-child(4) {
    animation-duration: 2s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: placeHolderShimmer;
    animation-timing-function: linear;
    background-color: #f6f7f8;
    background: linear-gradient(to right, #eeeeee 8%, #bbbbbb 18%, #eeeeee 33%);
    background-size: 800px 104px;
    position: relative;    
  }
  
  #funnel1 #funnel:nth-child(5) {
    animation-duration: 2s;
      animation-fill-mode: forwards;
      animation-iteration-count: infinite;
      animation-name: placeHolderShimmer;
      animation-timing-function: linear;
      background-color: #f6f7f8;
      background: linear-gradient(to right, #eeeeee 8%, #bbbbbb 18%, #eeeeee 33%);
      background-size: 800px 104px;
      position: relative;    
      }

  
  /* #funnel1 #funnel span {
    content: '';
    position: absolute;
    width: 400px;
    background: #888;
    left: -200px;
    height: 10px
  }
   */
  #funnel1 #funnel:nth-child(2)::before {
    width: 95%;
  }
  #funnel1 #funnel:nth-child(3)::before {
    width: 80%;
  }
  #funnel1 #funnel:nth-child(4)::before {
    width: 70%;
  }
  
  #funnel1 #funnel:nth-child(5)::before {
    width: 60%;
  }
  #funnel1 #funnel:nth-child(6)::before {
    width: 50%;
  }
  
  
  