div{
    font-family: 'Nunito Sans'!important;
}
.css-10hburv-MuiTypography-root{
    font-family: 'Nunito Sans'!important;
}
.css-maj24w-MuiTypography-root{
    font-family: 'Nunito Sans'!important;
}
.MuiTypography-root{
    font-family: 'Nunito Sans'!important;
}
/* .MuiList-padding{
    paddin-top: 0px;
} */
.color{
    color:rgba(0, 0, 0, 0.38);
}
.MuiListItemText-root{
    padding: 4px 16px !important;
}

.listHeader{
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 3px 110px;

    column-gap: 579px;
    /* row-gap:calc(2); */
    width: 792px;
    height: 32px;
}

.highligt{
    background: linear-gradient(0deg, rgba(55, 234, 158, 0.08), rgba(55, 234, 158, 0.08)), #FFFFFF;
    border-radius: 18px;
}

.actionButtonBottom{
    /* position: absolute; */
    /* bottom: 0; */
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.bottomCaption{
    width: 195px;
height: 20px;
width:240px;
/* caption */

font-family: 'Nunito Sans';
font-style: normal;
font-weight: 400;
font-size: 12px;
line-height: 166%;
/* identical to box height, or 20px */

letter-spacing: 0.4px;

/* text/primary */

color: rgba(0, 0, 0, 0.87);

}

.hidenColor{
    color:rgba(0, 0, 0, 0.38)!important;

}

.buttonCaption{
    color:#37CAD3;
    cursor: pointer;
    text-decoration: none;
    border: none;
    /* padding: 12px 40px; */
    /* font-size: 16px; */
    /* background-color: green; */
    /* color: #fff; */
    /* border-radius: 5px; */
    /* box-shadow: 7px 6px 28px 1px rgba(0, 0, 0, 0.24); */
    /* cursor: pointer; */
    /* outline: none; */
    transition: 0.2s all;
}

.title{
    
width: 37px;
height: 32px;
margin: 30px;
font-family: 'Nunito Sans';
font-style: normal;
font-weight: 700;

font-size: 16px;
line-height: 266%;
/* identical to box height, or 32px */

letter-spacing: 1px;
text-transform: uppercase;

/* text/primary */

color: rgba(0, 0, 0, 0.87);


}